<template>
	<div class="delivery-assistant">
		<!-- 头部搜索栏 -->
		<Header @changeRole="changeRole"></Header>
		<div v-if="rolesInfo.dealerId" class="delivery-assistant-c">
			<!-- 方案 -->
			<Programme :rolesInfo="rolesInfo"></Programme>
			<!-- 代办事项 -->
			<ToDo :rolesInfo="rolesInfo" :toDoTime="toDoTime" />
			<DeliveryAppointment :rolesInfo="rolesInfo" @checkMore="e=>$emit('checkMore',e,rolesInfo)"/>

			<!-- 交付排行 -->
			<PerformanceRank
				:rolesInfo="rolesInfo"
				:userInfo="userInfo"
			></PerformanceRank>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Header from './components/Header.vue'
import Programme from './components/Programme.vue'
import PerformanceRank from './components/PerformanceRank.vue'
import ToDo from './components/ToDo.vue'
import DeliveryAppointment from './components/DeliveryAppointment.vue'
export default {
  components: {
    Header,
    Programme,
    ToDo,
    PerformanceRank,
    DeliveryAppointment,
  },
  data() {
    return {
      rolesInfo: '',
      fromAssistantToDo: false,
      toDoTime: '',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/todo-list') {
        // 刷新待办列表和日历上的数据
        vm.$refs.todo.onRefresh()
        setTimeout(() => {
          const todoEle = document.getElementById('todo')
          if (todoEle) todoEle.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }
    })
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
  },
  methods: {
    // 切换角色
    changeRole(data) {
      this.rolesInfo = data
    },
  },
}
</script>
<style lang="less" scoped>
.delivery-assistant {
	color: @black;
	.delivery-assistant-c {
		padding: 12px 16px;
	}
	.common-block:not(:last-of-type) {
		margin-bottom: 16px;
	}
}
</style>
