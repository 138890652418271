import request from '@/utils/request'
import SERVER from './server'

import { thenResolve } from '@/utils/index'

/**
 * 工作概览
 */
export const deliveryWorkOverview = (params) =>
  request.post(
    `${SERVER.STATISTICS_SERVICE}/api/v1/delivery/work-overview`,
    params
  )
/**
 * 员工完成统计
 */
export const deliveryRank = (params) =>
  request.post(
    `${SERVER.STATISTICS_SERVICE}/api/v1/delivery/deliveryRank`,
    params
  )
/**
 * 预约提车日历
 */
export const deliveryAppointmentDate = (params) =>
  request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/delivery/book-date`, params)

/**
 * 交付待办
 */
export const deliveryTodo = (params) =>
  request.post(`${SERVER.STATISTICS_SERVICE}/api/v1/delivery/deliveryTodo`, params).then(thenResolve)
