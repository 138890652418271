<template>
	<div class="header">
		<div class="name">{{ userInfo.name }}</div>
		<div class="roles">
			<van-dropdown-menu v-if="roleList.length > 1" active-color="#B9921A">
				<van-dropdown-item v-model="roleActive" clearable :options="roleList" />
			</van-dropdown-menu>
			<div v-else style="padding-left: 8px;">{{ roleList[0]?.text }}</div>
		</div>
		<div class="icons">
			<img class="img" :src="searchIcon" @click="search" />
		</div>
	</div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import remindIcon from '@/images/remind_icon.svg'
import searchIcon from '@/images/search_icon.svg'
export default {
  data() {
    return {
      remindIcon,
      searchIcon,
      roleActive: '',
      searchStr: '',
      remindList: [1],
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    roleList() {
      const roles = (this.dictHash || [])[1014] || []
      if (roles.length == 0) return []
      let arr = []
      const { dealers=[] } = this.userInfo
      dealers.forEach(item => {
        let { roleLogos=[] } = item
        roleLogos = roleLogos.filter((value) => {
          return ['1014007', '1014006'].includes(value)
        })
        const sortList = ['1014007', '1014006']
        roleLogos = roleLogos.sort((a, b) => {
          return sortList.indexOf(a) - sortList.indexOf(b)
        })
        arr = arr.concat(
          roleLogos.map((roleItem) => {
            return {
              value: `${item.id}_${roleItem}`,
              roleName: roles.find(({ code }) => code === roleItem)?.name,
              dealerName: item.name,
              text: `${item.name}-${roles.find(({ code }) => code === roleItem)?.name}`,
            }
          })
        )
      })
      return arr
    },
  },
  watch: {
    roleList: {
      handler(val) {
        if (Array.isArray(val) && val.length > 0)
          this.roleActive =
						sessionStorage.getItem('deliveryAssistant_checkedRole') || val[0].value
      },
      immediate: true,
    },
    roleActive: {
      handler(val) {
        const roles = (this.dictHash ||[])[1014 ] || []
        sessionStorage.setItem('deliveryAssistant_checkedRole', val)
        const dealerId = val.split('_')[0]
        const roleCode = val.split('_')[1]
        store.commit('app/setSelectedShopId', dealerId)
        this.$nextTick(() => {
          this.$emit('changeRole', { dealerId, roleCode })
        })
      },
      immediate: true,
    },
  },
  methods: {
    // 点击搜索
    search() {
      this.$router.push({
        path: '/shop-clue-search',
      })
    },
    // 改变角色
    changeRole(val) {
      this.$emit('changeRole', val)
    },
    goMsg() {
      this.$toast(this.$t('功能开发中 敬请期待！'))
    },
  },
}
</script>
<style lang="less" scoped>
.header {
	display: flex;
	justify-content: space-between;
	height: 48px;
	align-items: center;
	background: #fff;
	padding: 0 16px;
	// position: sticky;
	// top: 0;
	z-index: 10;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
	.name {
		max-width: 68px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 16px;
		font-weight: 500;
	}
	.roles {
		flex: 1;
		/deep/ .van-dropdown-menu {
			.van-dropdown-menu__bar {
				height: 22px;
				background: none;
				box-shadow: none;
				.van-dropdown-menu__item {
					justify-content: flex-start;
				}
				.van-dropdown-menu__title {
					font-size: 13px;
				}
				.van-dropdown-menu__title::after {
					border-color: transparent transparent @black @black;
				}
			}
		}
	}
	.icons {
		max-width: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.img {
			width: 22px;
			position: relative;
			&.remind {
				width: 24px;
			}
			span {
				width: 8px;
				height: 8px;
				border-radius: 8px;
				background-color: #e4002c;
				position: absolute;
				right: 2px;
				top: 2px;
				// margin-top: -4px;
				// margin-right: -4px;
			}
		}
	}
}
</style>
