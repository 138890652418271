<template>
	<div class="order-item">
		<div class="orderTitle-id mb-8">
			<span class="blod">{{dataSource.orderType === 3? dataSource.appOrderId: dataSource.dcosOrderId}}</span>
			<span class="status">
				{{ dataSource.deliveryStatus | codeFormat }}
			</span>
		</div>
		<div v-if="dataSource.customerName" class="name mb-8">
			{{ dataSource.customerName }}
		</div>
		<div class="tag mb-8">
			<div>
				{{ dataSource.customerType == 1 ? $t('个人') : $t('企业') }}
			</div>
			<template v-if="[1, 0].includes(dataSource.installmentsFlag)">
				<div class="gap"></div>
				<div>
					{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
				</div>
			</template>
			<template v-if="dataSource.bigType">
				<div class="gap"></div>
				<div class="bigTye">
					{{ dataSource.bigType == 1 ? $t('厂端大客户') : $t('代理商大客户') }}
				</div>
			</template>
		</div>
		<div class="car-info mb-8" v-html="carInfo"></div>
		<div class="mb-8" style="color: rgba(13,23,26,0.45)">
			VIN： {{ dataSource.vin || '--' }}
		</div>
		<div class="follow-user">
			<span>{{ $t('交付专员') }}：</span>
			<span>
				{{
					dataSource.deliveryUserName || dataSource.deliveryPreUserName || '--'
				}}
			</span>
			<span>
				{{
					!dataSource.deliveryUserName && dataSource.deliveryPreUserName
						? `(${$t('预分配')})`
						: ''
				}}
			</span>
		</div>
		<ul class="options">
			<ul class="tag-ist">
				<li
					v-if="dataSource.balancePayStatus"
					class="li"
					:class="{ 'li-4011003': dataSource.balancePayStatus == 4011003 }"
				>
					{{ dataSource.balancePayStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.invoiceStatus"
					class="li"
					:class="{ 'li-202402': dataSource.invoiceStatus == 202402 }"
				>
					{{ dataSource.invoiceStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.financeStatus"
					class="li "
					:class="{ 'li-40050012': dataSource.financeStatus == 40050012 }"
				>
					{{ dataSource.financeStatus | codeFormat }}
				</li>
				<li v-if="dataSource?.insureStatus" class="li 4002 insureStatus">{{ dataSource.insureStatus | insuranceCodeFormat }}</li>
			</ul>
			<span v-if="isTimeOut" class="timeout">{{ $t('超期未提车') }}</span>
		</ul>
	</div>
</template>
<script>
import dayjs from 'dayjs'

export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    font: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    carInfo() {
      const {
        seriesName,
        modelName,
        colourName,
        interiorName,
      } = this.dataSource
      const arr = []
      if (seriesName) {
        arr.push(`<span>${seriesName}</span>`)
      }
      if (modelName) {
        arr.push(`<span>${modelName}</span>`)
      }
      if (colourName) {
        arr.push(`<span>${colourName}</span>`)
      }
      if (interiorName) {
        arr.push(`<span>${interiorName}</span>`)
      }
      return arr.join(
        '<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>'
      )
    },
    isTimeOut(){
      return dayjs(this.dataSource.bookDeliveryTime).isBefore(dayjs()) && this.dataSource.deliveryStatus !== '4000008'
    }
  },
}
</script>
<style lang="less" scoped>
.mb-8 {
	margin-bottom: 8px;
}
.car-info {
	display: flex;
	align-items: center;
	color: rgba(13, 23, 26, 0.45);
}
.order-item {
	border-radius: 8px;
	padding: 12px;
	background: white;
	font-size: 13px;
	.orderTitle-id {
		display: flex;
		align-items: center;
		padding-bottom: 12px;
		border-bottom: 1px solid rgba(13, 23, 26, 0.05);
		line-height: 16px;
		.blod {
			flex: 1;
		}
		.status {
			font-size: 14px;
			color: #029640;
		}
	}
	.tag {
		display: flex;
		align-items: center;
		color: #b9921a;
	}

	.gap {
		width: 1px;
		margin: 0 8px;
		height: 12px;
		background-color: #d9d9d9;
	}
}
.options {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: -4px;
	margin-right: -4px;
	.tag-ist{
		display: flex;
		flex-wrap: wrap;
		flex: 3;
	}
	.li {
		padding: 2px 8px;
		border-radius: 4px;
		border-width: 1px;
		border-style: solid;
		margin-top: 8px;
		margin-left: 4px;
		line-height: 16px;
		font-size: 12px;

		&-4011003 {
			border-color: rgba(255, 125, 0, 0.1);
			color: #ff7d00;
			background: rgba(255, 125, 0, 0.1);
		}

		&-202402 {
			color: #029640;
			background: rgba(2, 150, 64, 0.1);
			border-color: rgba(2, 150, 64, 0.1);
		}
		&-40050012 {
			color: #b9921a;
			background: rgba(185, 146, 26, 0.1);
			border-color: rgba(185, 146, 26, 0.1);
		}
		&:last-child {
			margin-right: 4px;
		}
	}
	.timeout{
		color: red;
		flex: 1;
		margin-top: 8px;
		text-align: right;
	}
}
</style>
