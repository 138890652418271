<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/zh-cn'
import { dateFormat } from '@/utils'
import loading from '@/utils/loading'
import { deliveryAppointmentDate } from '@/services/deliveryAssistant'
const date = dayjs()
dayjs.extend(weekday)
dayjs.locale('zh-cn')
export default {
  props: {
    initDate: {
      type: [Date, Object, Function, String, Number],
      default: () => date,
    },
    dealerId: {
      type: String,
      default: '',
    },
    roleCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nDate: date,
      dateData: {
        0: {
          title: this.$t('一'),
          weekEn: 'Mon',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        1: {
          title: this.$t('二'),
          weekEn: 'Tue',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        2: {
          title: this.$t('三'),
          weekEn: 'Wed',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        3: {
          title: this.$t('四'),
          weekEn: 'Thu',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        4: {
          title: this.$t('五'),
          weekEn: 'Fri',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        5: {
          title: this.$t('六'),
          weekEn: 'Sat',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
        6: {
          title: this.$t('日'),
          weekEn: 'Sun',
          date: '',
          day: '',
          className: '',
          text: '',
          count: '',
        },
      },
      today: dayjs().format('YYYY-MM-DD'),
      activeDay: dayjs().format('YYYY-MM-DD'),
      calendarList: [],
    }
  },
  watch: {
    initDate: {
      handler(val) {
        this.activeDay = val
          ? dayjs(val).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD')
        this.initList()
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    initList() {
      let { initDate, dateData } = this
      if (!initDate) initDate = dayjs()
      if (initDate) {
        for (const key in dateData) {
          const item = dateData[key]
          item.text = ''
          item.className = ''
          const date = dayjs(initDate).weekday(key)
          this.nDate.isSame(dayjs(date), 'date') && (item.text = this.$t('今'))
          item.value = dateFormat(date, 'YYYY-MM-DD')
          item.day = dateFormat(date, 'D')
        }
        this.getToDoCalendar()
      }
    },
    // 改变时间
    changeTime(date) {
      if (this.activeDay != date.value) {
        this.activeDay = date.value
      } else {
        // wathch 没更新，需手动调用
        this.getToDoCalendar()
      }
      this.$emit('change', [
        dayjs(date.value).format('YYYY-MM-DD 00:00:00'),
        dayjs(date.value).format('YYYY-MM-DD 23:59:59'),
      ])
    },
    async getToDoCalendar() {
      const { dealerId, roleCode } = this
      loading.showLoading()
      const { dateData } = this
      const params = {
        dealerId,
        roleCode,
        statisticsTime: {
          start: dateFormat(dateData[0].value, 'YYYY-MM-DD 00:00:00'),
          end: dateFormat(dateData[6].value, 'YYYY-MM-DD 23:59:59'),
        },
      }
      this.calendarList = []
      const result = await deliveryAppointmentDate(params)
      loading.hideLoading()
      const { code, data, msg } = result
      if (code === 0) {
        this.calendarList = data
      } else {
        this.$toast(msg)
      }
    },
    getCount(item) {
      return (
				this.calendarList.find(({ planDate }) => {
				  return planDate === item.value
				})?.count || 0
      )
    },
  },
}
</script>

<template>
	<div class="dateList">
		<div ref="calendarBox" class="calendarBox">
			<div
				v-for="(item, index) in dateData"
				:key="index"
				:class="[
					'calendar-i',
					item.value === activeDay ? 'calendar-i-active' : '',
				]"
				@click="changeTime(item)"
			>
				<div class="text">{{ item.weekEn }}</div>
				<div
					:class="[
						'calendar-i-c',
						item.value === activeDay ? 'calendar-i-c-active' : '',
						item.value === today ? 'today' : '',
					]"
				>
					<!-- <div v-if="item.value === today" class="today">
            {{ $t('今') }}
						{{ item.value | timeFormat('DD') }}
          </div> -->
					<div v-if="item.value === activeDay" class="calendar">
						{{ item.value | timeFormat('MM-DD') }}
					</div>
					<div v-else class="time">{{ item.value | timeFormat('DD') }}</div>
					<div class="value">{{ getCount(item) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="less" scoped>
.dateList {
	background-color: #fff;
	.calendarBox {
		display: flex;
		width: 100%;
		min-height: 94px;
		justify-content: space-between;
		position: relative;
		.calendar-i {
			width: 14%;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			height: auto;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			.text {
				font-size: 14px;
				color: rgba(13, 23, 26, 0.45);
				height: 30px;
				line-height: 28px;
			}
			.calendar-i-c {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 52px;
				width: 42px;
				padding-top: 10px;
				&.today {
					background: #e5e5e5;
					color: @black;
					font-size: 12px;
					font-weight: 500;
					border-radius: 6px;
				}
				&.calendar-i-c-active {
					background-color: #0d171a;
					color: #eed484;
					border-radius: 6px;
					overflow: hidden;
				}
				.time {
					flex: 1;
					font-weight: 600;
				}
				.today {
					flex: 1;
				}
				.calendar {
					flex: 1;
				}
				.value {
					margin-bottom: 3px;
          font-size: 12px;
          color: @yellow-text;
				}
			}
		}
	}
}
</style>
