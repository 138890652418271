<template>
	<div class="">
		<Home v-if="active == 'deliveryAssistantHome'" @checkMore="checkMore"></Home>
		<DeliveryList v-else-if="active == 'deliveryAssistantList'"></DeliveryList>
		<DeliveryApp v-else-if="active == 'deliveryAssistantApp'"></DeliveryApp>
		<van-tabbar
			:value="active"
			placeholder
			active-color="#B9921A"
			:before-change="beforeChange"
			@change="onChange"
		>
			<van-tabbar-item name="deliveryAssistantHome">
				<span>{{ $t('首页') }}</span>
				<template #icon="props">
					<img :src="props.active ? homeIconActive : homeIcon" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="deliveryAssistantList" icon="setting-o">
				{{ $t('交付单') }}
				<template #icon="props">
					<img :src="props.active ? deliveryIconActive : deliveryIcon" />
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="deliveryAssistantApp">
				<span>{{ $t('工作台') }}</span>
				<template #icon="props">
					<img :src="props.active ? stagingIconActive : stagingIcon" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
import homeIcon from '@/images/home_icon.png'
import deliveryIcon from './images/交付未点击@2x.png'
import stagingIcon from '@/images/staging_icon.png'
import homeIconActive from '@/images/home_icon_active.png'
import deliveryIconActive from './images/交付点击@2x.png'
import stagingIconActive from '@/images/staging_icon_active.png'
import Home from './home.vue'
import DeliveryApp from './DeliveryApp.vue'
import DeliveryList from './DeliveryList.vue'
export default {
  name: 'deliveryAssistantHome',
  components: {
    Home,
    DeliveryApp,
    DeliveryList,
  },
  data() {
    return {
      deliveryIcon,
      deliveryIconActive,
      homeIcon,
      homeIconActive,
      stagingIcon,
      stagingIconActive,
      active: ['deliveryAssistantHome', 'deliveryAssistantList', 'deliveryAssistantApp'].includes(this.$route.name) ? this.$route.name : 'deliveryAssistantHome',
      activeTabVal: 0,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.path === '/delivery-assistant/list') {
        vm.$store.commit('app/addKeepAlive', 'deliveryAssistantHome')
      }
    })
  },
  methods: {
    beforeChange(name) {
      // if (name == 'deliveryAssistantApp') {
      //   this.$toast(this.$t('功能开发中 敬请期待！'))
      //   return false
      // } 
      return true
    },
    onChange(name) {
      this.activeTabVal = 0
      this.$router.push({
        name: `${name}`,
        query: {
          replace: true
        },
      }).then(() => {
        this.$nextTick(() => {
          this.active = name
        })
      })
    },
    // 跳转交付单
    checkMore({ start,end },{ roleCode }) {
      this.$router.push({
        path: '/deliver-manage',
        query:{
          bookDeliveryTimeStart: start,
          bookDeliveryTimeEnd: end,
          deliveryStatuses: ['4000006','4000007','4000008','4000010'],
          roleCode
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
/deep/.van-tabbar--fixed {
	max-width: 500px;
	left: 50%;
	transform: translateX(-50%);
}
</style>
