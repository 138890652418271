<template>
  <div class="deliveryList">
    <List :activeTabVal="activeTabVal" v-bind="$attrs"></List>
  </div>
</template>
<script>
import List from '@/modules/deliverManage/index.vue'
export default {
  name: 'deliverAssistantList',
  components: {
    List
  },
  props: {
    activeTabVal: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {

    }
  },
  methods: {}
}
</script>
<style lang="less" scoped></style>